import React, { useCallback, useEffect, useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { InputSliderContainer } from './input-slider-styled';
import InputRangeBox from 'components/input-range-box/input-range-box';

const MIN = -30;
const MAX = 30;
const DEFAULT_VALUES = [-7, 5];

const DualPointerSlider = ({ name, title, onChange, currentValue }) => {
    const [values, setValues] = useState(currentValue);

    useEffect(() => {
        setValues(currentValue);
    }, [currentValue]);

    const handleChange = useCallback(
        newValues => {
            let [leftValue, rightValue] = newValues;

            // Enforce range restrictions
            if (leftValue >= 0) {
                leftValue = -1 * leftValue;
            }

            if (rightValue <= 0) {
                rightValue = -1 * rightValue;
            }

            if (leftValue >= rightValue) {
                leftValue = rightValue - 1;
            }

            const adjustedValues = [leftValue, rightValue];

            setValues(adjustedValues);

            onChange?.(name, { left: leftValue, right: rightValue });
        },
        [onChange, name]
    );

    const resetToDefault = useCallback(() => {
        setValues(DEFAULT_VALUES);

        onChange?.(name, { left: DEFAULT_VALUES[0], right: DEFAULT_VALUES[1] });
    }, [onChange, name]);

    return (
        <InputSliderContainer>
            <InputRangeBox
                onChange={handleChange}
                value={values}
                range={[MIN, MAX]}
                allowCross={false}
                title={title}
                isFullWidth
            />
            <div onClick={resetToDefault}>
                <FaSync color="var(--uiPrimaryColor)" />
            </div>
        </InputSliderContainer>
    );
};

export default DualPointerSlider;
