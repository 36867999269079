import styled, { keyframes } from 'styled-components/macro';

import { TrashIcon } from 'components/svg-icons/trash';
import Button from 'components/button/button';

import {
    boxShadow,
    containerRowHeight,
    fullScreenDialogWrapperZIndex,
    playlistMinHeight,
    rowBorderRadius,
    zIndexesVideoPlayer
} from '../constants/video-player-configs';

import {
    playlistBackgroundColor,
    playlistFontColor,
    playlistIconColor,
    videoPlayerBordersPalette,
    videoPlayerPalette
} from '../constants/video-player-palette';
import {
    playlistContainerHeight,
    playlistContainerLeft,
    playlistContainerTop,
    playlistContainerWidth
} from '../helpers/playlist-disposition-helpers';

const {
    cancelColor,
    playlistBackgroundTransparentColor,
    playlistBackgroundTransparentFullscreenColor,
    playlistActiveHeaderBackground,
    hoverColor,
    playlistFontColorLight,
    playlistBackgroundOpacityColor,
    playlistBackgroundHiddenColor,
    borderNonActive,
    borderErrorField,
    borderIsActive,
    rowBorderColor,
    lightFontColor,
    defaultActiveControlColor,
    defaultNonActiveControlColor,
    whiteFontColor,
    onHoverColor,
    videoPlayerBackgroundColor,
    playlistFullScreenOnMobileBackgroundColor,
    playlistDialogFullscreenBackgroundColor
} = videoPlayerPalette;

const { activeSelectorBorder, selectorOfElementBorder, newPlaylistConfirmBorder } = videoPlayerBordersPalette;

const smallText = {
    mobile: { fontSize: '10px', fontWeight: 200 },
    default: {
        fontSize: '0.6rem',
        fontWeight: 100
    }
};

const usualText = {
    mobile: { fontSize: '12px', fontWeight: 200 },
    default: {
        fontSize: '0.7rem',
        fontWeight: 100
    }
};

const titleText = {
    mobile: { fontSize: '14px', fontWeight: 400 },

    mobileL: { fontSize: '14px', fontWeight: 600 },
    default: {
        fontSize: '0.9rem',
        fontWeight: 500
    },
    defaultL: {
        fontSize: '0.9rem',
        fontWeight: 600
    }
};

const titleHeaderText = {
    mobile: { fontSize: '14px', fontWeight: 600 },
    default: {
        fontSize: '0.9rem',
        fontWeight: 600
    }
};

const PlaylistContainer = styled.div`
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: calc(4 * ${rowBorderRadius});

        box-shadow: inset 0 0 6px ${playlistIconColor};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: calc(4 * ${rowBorderRadius});

        box-shadow: inset 0 0 6px ${playlistIconColor};
    }
`;

export const PlaylistsContainer = styled(PlaylistContainer)`
    position: ${props =>
        (props.isMobile && props.windowConfig && props.windowConfig.isPortrait) || props.iframeMode
            ? 'relative'
            : 'absolute'};

    top: ${props => playlistContainerTop(props)};
    left: ${props =>
        props.fullscreen ? `calc(100% - ${playlistContainerWidth(props)})` : playlistContainerLeft(props)};

    z-index: ${props => (props.fullscreen ? zIndexesVideoPlayer.playlist : 'auto')};

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    min-width: ${props => playlistContainerWidth(props)};
    max-width: ${props => playlistContainerWidth(props)};
    height: ${props => playlistContainerHeight(props)};

    min-height: ${props => (props.interfaceIsHidden ? `38px` : `calc(${playlistMinHeight}px)`)};
    max-height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    border-radius: calc(2 * ${rowBorderRadius});

    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};

    background-color: ${props =>
        props.interfaceIsHidden
            ? playlistBackgroundHiddenColor
            : props.fullscreen && !props.isMobile
            ? playlistBackgroundOpacityColor
            : props.fullscreen
            ? playlistFullScreenOnMobileBackgroundColor
            : playlistBackgroundColor};

    user-select: none;
`;

export const PlayListControlsWrapper = styled.div`
    display: ${props => (props.interfaceIsHidden ? 'none' : 'flex')};
    flex-direction: row;
    width: 100%;
    min-height: ${props => (props.isMobile ? `calc(1.75 * ${containerRowHeight})` : `calc(2 * ${containerRowHeight})`)};
    padding: 0.1rem;

    border-top-left-radius: calc(2 * ${rowBorderRadius});
    border-top-right-radius: calc(2 * ${rowBorderRadius});
`;

export const PlaylistViewerWrapper = styled.div`
    position: relative;

    display: ${props => (props.interfaceIsHidden ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - (1.5 * ${containerRowHeight}));
    min-height: ${props => (props.isMobile ? '30vh' : `calc(${playlistMinHeight}px - 2 * ${containerRowHeight})`)};
    padding: ${props => (props.isMobile ? 0 : `calc(0.05 * ${containerRowHeight})`)};

    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};
`;

export const PlayListControlsButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;

    min-height: ${props => (props.isMobile ? `calc(1.75 * ${containerRowHeight})` : `calc(2 * ${containerRowHeight})`)};

    border: none;
    border-top-left-radius: ${props => props.left && '1em'};
    border-top-right-radius: ${props => props.right && '1em'};

    color: ${props =>
        props.isActive && !props.fullscreen
            ? playlistIconColor
            : props.isActive && props.fullscreen
            ? playlistFullScreenOnMobileBackgroundColor
            : props.fullscreen
            ? playlistFontColorLight
            : lightFontColor};
    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};

    text-transform: uppercase;

    background: ${props =>
        props.isActive && !props.fullscreen
            ? defaultActiveControlColor
            : props.isActive && props.fullscreen
            ? playlistBackgroundTransparentColor
            : props.fullscreen
            ? playlistBackgroundOpacityColor
            : defaultNonActiveControlColor};
    cursor: pointer;
`;

export const PlaylistContainerListWrapper = styled(PlaylistContainer)`
    position: relative;

    display: ${props => (props.isHidden ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    min-height: ${props =>
        props.extraHeight
            ? `calc(10 * ${containerRowHeight})`
            : props.notEmpty
            ? `calc(${Math.min(props.minLength, 10)} * ${containerRowHeight})`
            : `calc(${containerRowHeight})`};
    max-height: 100%;
    overflow-y: auto;

    color: ${props => (props.fullscreen ? playlistFontColorLight : playlistFontColor)};

    background: ${props =>
        props.fullscreen && !props.isMobile
            ? playlistBackgroundOpacityColor
            : props.fullscreen && props.isMobile
            ? playlistFullScreenOnMobileBackgroundColor
            : videoPlayerBackgroundColor};

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
`;

export const DefaultPlaylistControlsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TeamFilterWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const TeamFilterControl = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
        props.isLeft && props.isActive ? '#3A66AB' : props.isRight && props.isActive ? '#C55267' : 'rgba(0,0,0,0.5)'};
    border-top-left-radius: ${props => (props.isLeft ? '5px' : 'none')};
    border-top-right-radius: ${props => (props.isRight ? '5px' : 'none')};
    border-bottom-right-radius: ${props => (props.isRight ? '5px' : 'none')};
    border-bottom-left-radius: ${props => (props.isLeft ? '5px' : 'none')};

    background: ${props =>
        props.isLeft && props.isActive
            ? 'rgba(58,102,171,0.33)'
            : props.isRight && props.isActive
            ? 'rgba(197,82,103,0.33)'
            : 'auto'};

    &:hover {
        box-shadow: ${boxShadow};
        cursor: pointer;
    }
`;

export const TeamFilterLabel = styled('span')`
    color: ${props =>
        props.isLeft && props.isActive
            ? '#3A66AB'
            : props.isRight && props.isActive
            ? '#C55267'
            : 'var(--defaultColor)'};
    font-weight: bold;
    line-height: 2em;
    text-transform: uppercase;
`;

export const PlaylistAddToCustomControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: ${props => (props.isMobile ? `calc(0.8 * ${containerRowHeight})` : containerRowHeight)};
`;

export const ActivateSelector = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: ${props => (props.width ? props.width : '100%')};
    min-height: ${props =>
        props.isMobile ? `calc(1.2 * ${containerRowHeight})` : `calc(1.5 * ${containerRowHeight})`};

    border: ${props => (props.isMobile ? 'none' : activeSelectorBorder)};
    border-radius: calc(2 * ${rowBorderRadius});

    color: ${playlistFontColor};
    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};
    text-transform: uppercase;

    box-shadow: ${props => (props.isMobile ? boxShadow : 'none')};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    &:hover {
        box-shadow: ${({ isDisabled }) => (isDisabled ? 'none' : boxShadow)};
    }
`;

export const PlaylistElementWrapper = styled.div`
    position: relative;

    top: ${props => (props.extraHeight ? `calc(0.5 * ${containerRowHeight})` : 0)};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    min-height: ${props => (props.extraHeight ? `calc(6 * ${containerRowHeight})` : `${containerRowHeight}`)};
    overflow-y: hidden;
`;

export const PlaylistElementHeaderWrapper = styled.div`
    position: relative;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: ${props => (props.fullWidth ? 'space-between' : 'flex-start')};
    align-items: center;
    width: 100%;

    min-height: ${containerRowHeight};

    border-bottom: ${props => (props.isMobile ? 'none' : rowBorderColor)};

    background-color: ${props => (props.playlistIsActive ? playlistActiveHeaderBackground : 'none')};
    cursor: pointer;

    &:hover {
        background-color: ${onHoverColor};
        box-shadow: ${boxShadow};
    }
`;

export const ListIconWrapper = styled('div')`
    display: ${props => (!props.isHidden ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;

    min-height: ${containerRowHeight};
`;

export const PlaylistElementHeader = styled.div`
    display: ${props => (!props.isHidden ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: ${props => (props.width ? props.width : '80%')};
    min-height: ${containerRowHeight};
    padding-right: 5px;

    padding-left: 5px;

    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};
    text-transform: uppercase;

    &:hover {
        background-color: ${onHoverColor};
    }
`;

export const PlaylistHeaderLabel = styled('span')``;

export const PlaylistRowTime = styled.span`
    display: ${props => (!props.isHidden ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: center;
    align-items: center;

    min-width: calc(1 * ${containerRowHeight});
    max-width: calc(1 * ${containerRowHeight});
    height: ${containerRowHeight};

    font-weight: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontWeight}` : `${titleHeaderText.default.fontWeight}`};
    font-size: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontSize}` : `${titleHeaderText.default.fontSize}`};
`;

export const PlaylistRow = styled.span`
    display: ${props => (!props.isHidden ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 40%;
    max-width: 40%;
    height: ${containerRowHeight};
    padding-left: 5px;
    overflow: hidden;

    border-radius: ${rowBorderRadius};

    color: ${props => (props.homeTeamFilterIsActive ? '#3A66AB' : props.awayTeamFilterIsActive ? '#C55267' : 'auto')};

    font-weight: ${props =>
        props.isMobile
            ? props.homeTeamFilterIsActive || props.awayTeamFilterIsActive
                ? `${titleText.mobileL.fontWeight}`
                : `${usualText.mobile.fontWeight}`
            : props.homeTeamFilterIsActive || props.awayTeamFilterIsActive
            ? `${titleText.defaultL.fontWeight}`
            : `${usualText.default.fontWeight}`};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};

    white-space: nowrap;

    text-transform: uppercase;
    text-overflow: ellipsis;
`;

export const SelectorOfElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${containerRowHeight};
    height: ${containerRowHeight};
`;

export const SelectorWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(0.5 * ${containerRowHeight});
    height: ${containerRowHeight};
`;

export const SelectorOfElements = styled.div`
    width: calc(0.5 * ${containerRowHeight});
    height: calc(0.5 * ${containerRowHeight});

    border: ${selectorOfElementBorder};
    border-radius: ${rowBorderRadius};

    background-color: ${props => (props.isSelect ? playlistIconColor : playlistBackgroundColor)};
`;

export const FullScreenDialogWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    z-index: ${fullScreenDialogWrapperZIndex};

    display: ${props => (props.interfaceIsHidden ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100vw !important;
    min-height: 100vh !important;

    background-color: ${props => (props.noBackground ? '' : playlistBackgroundTransparentColor)};
`;

const opacityChanger = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const VideoPlayerFullScreenDialogContainer = styled.section`
    z-index: ${zIndexesVideoPlayer.addToPlaylist};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: ${props => (props.isMobile ? '100vw' : '300px')};
    max-width: ${props => (props.isMobile ? '100vw' : '60vw')};
    min-height: ${props => (props.isMobile ? '40vh' : '6rem')};
    max-height: ${props => (props.isMobile ? '80vh' : '80vh')};

    padding: ${props => (props.noPadding ? 0 : `calc(0.5 * ${containerRowHeight})`)};

    overflow-y: auto;

    border-radius: ${rowBorderRadius};

    background-color: ${props =>
        props.fullscreen && !props.isMobile
            ? playlistDialogFullscreenBackgroundColor
            : props.fullscreen && props.isMobile
            ? playlistFullScreenOnMobileBackgroundColor
            : playlistFontColorLight};

    box-shadow: ${boxShadow};

    animation-name: ${opacityChanger};
    animation-duration: 750ms;
`;

export const PlaylistAddToCustomDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(1.5 * ${containerRowHeight});

    color: ${props => (props.fullscreen ? `#fff` : playlistIconColor)};
    font-weight: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontWeight}` : `${titleHeaderText.default.fontWeight}`};
    font-size: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontSize}` : `${titleHeaderText.default.fontSize}`};
`;

export const PlaylistAddToCustomDialogHeaderLabel = styled.div`
    width: 80%;

    text-align: center;
    text-transform: uppercase;

    user-select: none;
`;

export const PlaylistAddToCustomControlWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: ${props =>
        props.isMobile ? `calc(1.3 * ${containerRowHeight})` : `calc(1.7 * ${containerRowHeight})`};
    padding: 0;

    border-top-left-radius: ${rowBorderRadius};
    border-bottom-left-radius: ${rowBorderRadius};

    &:hover {
        box-shadow: ${boxShadow};
    }
`;

export const PlaylistAddToCustomControlLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    min-height: ${props =>
        props.isMobile ? `calc(1.2 * ${containerRowHeight})` : `calc(1.5 * ${containerRowHeight})`};
    padding: 0;

    border: 1px solid ${playlistBackgroundTransparentColor};
    border-radius: ${rowBorderRadius};

    color: ${playlistBackgroundColor};

    background-color: ${props => (props.totalCount > 0 ? playlistIconColor : '#a7aac4')};

    cursor: ${props => (props.totalCount > 0 ? 'pointer' : 'auto')};

    &:hover {
        box-shadow: ${boxShadow};
    }
`;

export const PlaylistAddToCustomControlLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 25%;
    height: 100%;

    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};
    text-transform: uppercase;

    user-select: none;
`;

export const PlaylistAddToCustomControlResetWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: ${props => (props.isMobile ? containerRowHeight : `calc(1.5 * ${containerRowHeight})`)};

    border: 1px solid ${playlistBackgroundTransparentColor};
    border-radius: ${rowBorderRadius};

    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};

    cursor: pointer;

    &:hover {
        box-shadow: ${boxShadow};
    }
`;

export const NewPlaylistCreate = styled.button`
    width: 100%;
    height: calc(1.5 * ${containerRowHeight});

    padding: 0.25rem;

    border: none;

    color: ${playlistBackgroundColor};

    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};
    text-align: center;
    text-transform: uppercase;

    background-color: ${playlistIconColor};
`;

export const NewPlaylistControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    height: calc(2.5 * ${containerRowHeight});
    padding: 0.25rem;
`;
export const NewPlaylistInput = styled.input`
    width: 80%;
    min-height: calc(1 * ${containerRowHeight});

    border: ${newPlaylistConfirmBorder};

    font-weight: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontWeight}` : `${titleHeaderText.default.fontWeight}`};
    font-size: ${props =>
        props.isMobile ? `${titleHeaderText.mobile.fontSize}` : `${titleHeaderText.default.fontSize}`};
    text-align: center;
`;
export const NewPlaylistInputConfirm = styled.button`
    min-width: 5rem;
    min-height: calc(1 * ${containerRowHeight});

    border: ${newPlaylistConfirmBorder};
    border-radius: ${rowBorderRadius};

    color: ${playlistBackgroundColor};
    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};
    line-height: 1.5;
    letter-spacing: 0.15rem;
    text-transform: uppercase;

    background-color: ${props => (props.cancel ? videoPlayerPalette.cancelColor : playlistIconColor)};
    cursor: pointer;
`;

export const CustomPlaylistsNameLabels = styled(PlaylistContainer)`
    width: 100%;
    min-height: 200px;
    max-height: 55vh;
    padding: 0.5rem;
    overflow-y: auto;

    color: ${props => (props.fullscreen ? `#fff` : `#000`)};
    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};
`;

export const CustomPlaylistsNameLabel = styled.button`
    width: 100%;
    height: ${containerRowHeight}px;

    border: none;
    border-bottom: 1px solid #a6b1c4;

    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};
    line-height: 1.5;
    letter-spacing: 0.25rem;
    text-align: center;
    text-transform: uppercase;

    background: ${props => (props.processing ? 'rgba(175,209,227,0.2)' : 'none')};
    cursor: pointer;

    &:hover {
        color: ${hoverColor};

        background-color: darkgray;
        box-shadow: ${boxShadow};
    }
`;

export const PlaylistSharedDefaultElementsControlsWrapper = styled('div')``;

export const PlaylistSharedDefaultElements = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: calc(3 * ${containerRowHeight});
    height: 100%;
`;

export const PlaylistDataRowWrapper = styled(PlaylistContainer)`
    position: relative;

    display: ${props => (props.playlistIsOpen ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: ${containerRowHeight};
    padding-left: ${props => (props.isPartOfGroup ? '16px' : 'none')};
    overflow: auto;

    border-radius: ${rowBorderRadius};

    color: ${props =>
        props.trackIsActive && !props.fullscreen
            ? playlistBackgroundColor
            : props.fullscreen
            ? playlistFontColorLight
            : playlistFontColor};

    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};

    background-color: ${props => (props.trackIsActive ? playlistIconColor : 'none')};

    user-select: none;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &:hover {
        background-color: ${({ noHover }) => (noHover ? 'transparent' : onHoverColor)};
        box-shadow: ${boxShadow};
        cursor: pointer;
    }
`;

export const PlaylistElementControlWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 80%;
    padding-right: 3px;
    padding-left: 3px;

    font-weight: ${props => (props.isMobile ? `${smallText.mobile.fontWeight}` : `${smallText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${smallText.mobile.fontSize}` : `${smallText.default.fontSize}`)};
`;

export const ShareDialogWrapper = styled('section')`
    display: grid;
    grid-template-columns: ${props => (props.isMobile ? 'auto 2.5rem' : '40rem 2.5rem')};
    grid-template-rows: 0.75rem 4rem;
    width: 100%;
    height: 100%;

    cursor: default;
`;

export const CloseCircleButton = styled.button`
    display: flex;
    grid-column: 2;
    grid-row-end: 2;

    grid-row-start: 1;
    justify-content: center;
    align-items: center;

    width: ${containerRowHeight};
    height: ${containerRowHeight};
    margin: 0;
    padding: 0;

    border: none;
    border-radius: 2rem;

    color: ${whiteFontColor};
    font-weight: 700;
    font-size: 1.2rem;

    background: var(--cancelColor);

    user-select: none;

    &:hover {
        background: var(--againstColorActive);
        box-shadow: ${boxShadow};
        transform: matrix();
        cursor: pointer;

        animation-delay: 0.5s;
    }
`;

export const SharedDialogContainer = styled.section`
    display: flex;
    flex-direction: row;
    grid-column: 1;
    grid-row: 2;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    min-height: calc(2 * ${containerRowHeight});

    border-radius: ${rowBorderRadius};

    cursor: default;
`;

export const ShareLink = styled(PlaylistContainer)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    min-height: calc(1.5 * ${containerRowHeight});
    padding-right: 8px;
    padding-left: 8px;
    overflow: auto; /* Обрезаем всё за пределами блока */

    border: ${props => (props.noBorder ? 'none' : borderNonActive)};
    border-radius: ${rowBorderRadius};

    color: ${props => (props.fullscreen ? whiteFontColor : playlistFontColor)};

    white-space: nowrap; /* Текст не переносится */
    text-overflow: ellipsis; /* Добавляем многоточие */

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
`;
export const CopyButton = styled.button`
    display: flex;
    gap: 6px;
    align-items: center;
    min-height: calc(0.9 * ${containerRowHeight});

    border: none;
    border-radius: ${rowBorderRadius};

    color: ${playlistFontColorLight};
    text-transform: uppercase;

    background-color: ${playlistIconColor};

    &:hover {
        box-shadow: ${boxShadow};
        cursor: pointer;
    }
`;

export const PlaylistRenameInput = styled.input`
    width: 95%;
    height: ${containerRowHeight};

    border: ${props => (props.isEmpty ? `${borderErrorField}` : `${borderNonActive}`)};

    &:hover {
        border: ${props => (props.isEmpty ? `${borderErrorField}` : `${borderIsActive}`)};
    }
`;

export const PlaylistNewNameSubmit = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: ${containerRowHeight};

    padding-inline: 2px;

    border: none;
    border-radius: ${rowBorderRadius};

    color: ${playlistBackgroundColor};
    font-weight: bold;

    text-transform: uppercase;

    background-color: ${props => (props.cancel ? cancelColor : playlistIconColor)};
`;

export const RenameItemDialogWrapper = styled.div`
    position: relative;

    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    gap: 1px;
    align-items: flex-start;
    width: 100%;
    min-width: 10rem;
    min-height: calc(1 * ${containerRowHeight});

    padding: 0;

    border-radius: ${rowBorderRadius};

    background: ${props => (props.fullscreen ? playlistBackgroundTransparentFullscreenColor : playlistBackgroundColor)};
    box-shadow: ${boxShadow};
`;

export const RenameItemDialogInput = styled.input`
    width: 80%;
    height: ${containerRowHeight};

    border: ${props => (props.isEmpty ? `${borderErrorField}` : `${borderNonActive}`)};
    border-radius: ${rowBorderRadius};

    &:hover {
        border: ${props => (props.isEmpty ? `${borderErrorField}` : `${borderIsActive}`)};
    }
`;

export const EditItemDialogWrapper = styled(RenameItemDialogWrapper)``;

export const EditItemDialogInput = styled(RenameItemDialogInput)``;

export const EditEpisodeWrap = styled.div``;

export const PlaylistGroupWrapper = styled('div')`
    position: relative;

    width: 100%;

    min-height: ${containerRowHeight};
`;

export const PlaylistRowsGroupedWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: ${containerRowHeight};
    overflow-y: auto;
`;

export const SwitcherGroupVisibility = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 5px;

    border: none;

    color: ${props => (props.fullscreen ? whiteFontColor : playlistFontColor)};

    cursor: pointer;

    user-select: none;
`;

export const SwitcherLabel = styled.span`
    width: 70%;
`;

export const SwitcherCounter = styled.span`
    width: 20%;
`;

export const SwitcherSymbolToUp = styled.span`
    width: 0;
    height: 0;

    border-right: 5px solid transparent;
    border-bottom: 10px solid ${playlistFontColor};
    border-left: 5px solid transparent;
`;
export const SwitcherSymbolToDown = styled.span`
    width: 0;
    height: 0;

    border-top: 10px solid ${playlistFontColor};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
`;

export const LoaderWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: ${containerRowHeight};
`;

export const PlaylistCandidates = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    min-height: calc(10px + 2 * ${containerRowHeight});
    max-height: calc(10px + 8 * ${containerRowHeight});
    padding: 5px;

    overflow-y: auto;

    color: ${props => (props.fullscreen ? `#fff` : `#000`)};
    font-weight: ${props => (props.isMobile ? `${usualText.mobile.fontWeight}` : `${usualText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${usualText.mobile.fontSize}` : `${usualText.default.fontSize}`)};
`;

export const CandidateToPlaylist = styled('span')`
    min-width: 10rem;
    max-width: 20rem;
    max-height: calc(2 * ${containerRowHeight});
    padding: 0.1rem;

    border: none;

    font-weight: ${props => (props.isMobile ? `${titleText.mobile.fontWeight}` : `${titleText.default.fontWeight}`)};
    font-size: ${props => (props.isMobile ? `${titleText.mobile.fontSize}` : `${titleText.default.fontSize}`)};

    text-overflow: ellipsis;

    box-shadow: ${boxShadow};
    cursor: not-allowed;
`;

export const PlaylistRowBadgesWrapper = styled('div')`
    display: ${props => (!props.isHidden ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: flex-start;

    align-items: center;
    min-width: calc(1.5 * ${containerRowHeight});
    overflow: hidden;
`;

export const PlaylistFullScreenControlsWrapper = styled('div')`
    position: absolute;
    right: 0;
    z-index: 13;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    height: ${containerRowHeight};
    padding: 0.1rem;
`;

export const HideControl = styled('span')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 3rem;
    padding: 0.25rem;

    color: ${playlistBackgroundColor};
    font-weight: bold;
    font-size: smaller;
    letter-spacing: 0.1rem;
    text-align: center;
    text-transform: uppercase;

    background: ${playlistBackgroundOpacityColor};
`;

export const DetailMomentMenuWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-width: 70%;
    height: ${containerRowHeight};
`;

export const StatusGameMoment = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 100%;
    overflow-x: hidden;

    color: #000;
    font-size: 8px;
    white-space: nowrap;
    text-transform: uppercase;
    text-overflow: ellipsis;

    background-color: ${props => (props.backCol ? props.backCol : '#ccc')};
`;

export const StyledTrashIcon = styled(TrashIcon)`
    color: ${props => (props.isFullscreen ? '#fff' : 'rgba(74, 144, 226, 0.9)')};

    cursor: pointer;

    ${PlaylistDataRowWrapper}:hover & {
        color: rgba(74, 144, 226, 0.9);
    }
`;

export const SketchButton = styled(Button)`
    height: 32px;
    margin: 0;
    padding: 4px;

    color: ${({ isOpen }) => (!isOpen ? 'var(--uiPrimaryColorActive)' : 'var(--backgroundColorSecondary)')};

    background-color: ${({ isOpen }) => (isOpen ? 'var(--uiPrimaryColorActive)' : 'var(--optionsPrimaryColor)')};
`;
