import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './text-range-box.module.css';

const DEFAULT_VALUE = '';

const numericRegexPositive = /^[1-9][0-9]*$/;
const numericRegexNegative = /^-?[1-9][0-9]*$/;

const isNumericStringTop = value => numericRegexPositive.test(value);
const isNumericStringLow = value => numericRegexNegative.test(value);

const isConsistent = (lowValue, topValue) => lowValue <= topValue;

const isValid = (value, range) => isFinite(value) && value >= range?.[0] && value <= range?.[1];

const TextRange = ({ value, range, rangeLimit = { top: 1, low: -1 }, separator, onChange }) => {
    const [lowInvalid, setLowInvalid] = useState(false);
    const [topInvalid, setTopInvalid] = useState(false);
    const [lowValue, setLowValue] = useState(value?.[0] ?? DEFAULT_VALUE);
    const [topValue, setTopValue] = useState(value?.[1] ?? DEFAULT_VALUE);

    useEffect(() => {
        setLowValue(value?.[0] ?? DEFAULT_VALUE);
        setTopValue(value?.[1] ?? DEFAULT_VALUE);
    }, [value]);

    const onTopChange = useCallback(
        ({ target }) => {
            //On clear field
            if (!target.value) {
                setTopValue(target.value);

                return;
            }

            if (!isNumericStringTop(target.value)) {
                return;
            }

            const val = +target.value;

            if (!isValid(val, range)) {
                setTopInvalid(true);
                setTopValue(val);

                return;
            }

            if (!isConsistent(lowValue, val)) {
                setTopInvalid(true);
                setLowInvalid(true);
                setTopValue(val);

                return;
            }

            setTopInvalid(false);
            setLowInvalid(false);
            onChange?.([lowValue, val]);
        },
        [range, onChange, lowValue]
    );

    const onLowChange = useCallback(
        ({ target }) => {
            //On clear field
            if (!target.value || !(-1 * target.value)) {
                setLowValue(target.value);

                return;
            }

            if (!isNumericStringLow(target.value)) {
                return;
            }

            const val = +target.value;

            if (!isValid(val, range)) {
                setLowInvalid(true);
                setLowValue(val);

                return;
            }

            if (!isConsistent(val, topValue)) {
                setTopInvalid(true);
                setLowInvalid(true);

                setLowValue(val);

                return;
            }

            setTopInvalid(false);
            setLowInvalid(false);
            onChange?.([val, topValue]);
        },
        [range, onChange, topValue]
    );

    const onTopBlur = useCallback(
        ({ target }) => {
            if (!target.value) {
                onChange?.([lowValue, rangeLimit.top]);
            }
        },
        [onChange, rangeLimit, lowValue]
    );

    const onLowBlur = useCallback(
        ({ target }) => {
            if (!target.value || !(-1 * target.value)) {
                onChange?.([rangeLimit.low, topValue]);
            }
        },
        [onChange, rangeLimit, topValue]
    );

    return (
        <div className={styles.header}>
            <input
                className={cx(styles.value, { [styles.invalidInput]: lowInvalid })}
                value={lowValue}
                onChange={onLowChange}
                onBlur={onLowBlur}
            />

            {separator}

            <input
                className={cx(styles.value, { [styles.invalidInput]: topInvalid })}
                value={topValue}
                onChange={onTopChange}
                onBlur={onTopBlur}
            />
        </div>
    );
};

export default TextRange;
