import React from 'react';
import cx from 'classnames';
import { Range } from 'rc-slider';
import TextRange from './components/text-range';

import './global.css';
import styles from './input-range-box.module.css';

const InputRangeBox = ({ value, range, title, allowCross = true, isFullWidth = false, onChange }) => (
    <div className={cx(styles.root, { [styles.isRangeFullWidth]: isFullWidth })}>
        {title && <div className={styles.title}>{title}</div>}
        <TextRange value={value} onChange={onChange} range={range} />
        <Range value={value} onChange={onChange} min={range[0]} max={range[1]} allowCross={allowCross} />
    </div>
);

export default InputRangeBox;
