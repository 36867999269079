import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { createSelector } from 'reselect';

import FormattedMessage from 'components/formatted-message';
import {
    AMOUNT_MEASURES,
    AmountFormatter,
    NUMBER_FORMATS,
    QuantityFormatter
} from 'components/number-formatter/number-formatter';
import { getOvertimeWinner } from 'utils/game-ot-utils';
import * as teamStatusType from 'constants/team-status-type';
import * as winType from 'constants/win-type';
import { PREMIUM, PREMIUM_PLUS, productToPath } from 'containers/product-initializer/products';
import { SCOUT } from 'constants/game-types';
import { selector as productSelector } from 'containers/product-initializer/product-module';
import RedirectLink from 'components/redirect-link/redirect-link';

import styles from './game-preview.module.css';

const { hundredths } = AMOUNT_MEASURES;
const { float2 } = NUMBER_FORMATS;

const scoreSelector = createSelector(
    game => game,
    game => {
        const { firstPeriodScore, secondPeriodScore, thirdPeriodScore, otScore } = game;
        const score = [firstPeriodScore, secondPeriodScore, thirdPeriodScore, otScore];
        return score.filter(d => d);
    }
);

export const PeriodsScore = ({ game, isReport }) => {
    const score = scoreSelector(game);
    return (
        <div className={styles.periodScoreContainer}>
            {score
                .map(s => s.replace('-', ':'))
                .map((s, i) => (
                    <div
                        className={cx(
                            { [styles.periodScore]: !isReport },
                            { [styles.reportColor]: isReport },
                            { [styles.reportPeriodScore]: isReport }
                        )}
                        key={i}
                    >
                        {s}
                    </div>
                ))}
        </div>
    );
};

export const TotalXg = ({ data, leftKey = 'home', rightKey = 'away' }) => (
    <Fragment>
        <div className={styles.headerSubitem}>
            <div className={styles.infoName}>
                <FormattedMessage id="summary.score.evXg" />
            </div>
            <div className={styles.infoData}>
                <AmountFormatter value={data[leftKey].evXg} measure={hundredths} format={float2} /> -
                <AmountFormatter value={data[rightKey].evXg} measure={hundredths} format={float2} />
            </div>
        </div>
        <div className={styles.headerSubitem}>
            <div className={styles.infoName}>
                <FormattedMessage id="summary.score.stXg" />
            </div>
            <div className={styles.infoData}>
                <AmountFormatter value={data[leftKey].stXg} measure={hundredths} format={float2} /> -
                <AmountFormatter value={data[rightKey].stXg} measure={hundredths} format={float2} />
            </div>
        </div>
    </Fragment>
);

const renderWinType = type => {
    switch (type) {
        case winType.SO:
            return (
                <span className={styles.supLeft}>
                    <FormattedMessage id="score.SO" />
                </span>
            );
        case winType.OT:
            return (
                <span className={styles.supLeft}>
                    <FormattedMessage id="score.OT" />
                </span>
            );
        default:
            return null;
    }
};

const ScoreSups = ({ children, game }) => {
    const otWinner = getOvertimeWinner(game);
    return (
        <Fragment>
            {otWinner.teamStatus === teamStatusType.HOME && renderWinType(otWinner.type)}
            {children}
            {otWinner.teamStatus === teamStatusType.AWAY && renderWinType(otWinner.type)}
        </Fragment>
    );
};

export const fontStyleSelector = createSelector(
    homeScore => homeScore,
    (homeScore, awayScore) => awayScore,
    (homeScore, awayScore) => {
        const maxScore = homeScore > awayScore ? homeScore : awayScore;
        if (maxScore >= 1000) {
            return styles.quadroDigitScore;
        } else if (maxScore >= 100) {
            return styles.tripleDigitScore;
        } else if (maxScore >= 10) {
            return styles.doubleDigitScore;
        } else if (maxScore < 9) {
            return styles.singleDigitScore;
        }
    }
);

export const GameScore = ({ game, isReport }) => (
    <div
        className={cx(
            styles.score,
            { [styles.reportDigitScore]: isReport },
            { [styles.reportColor]: isReport },
            { [fontStyleSelector(game?.homeScore, game?.awayScore)]: !isReport }
        )}
    >
        <ScoreSups game={game}>
            <QuantityFormatter value={game?.homeScore} />:<QuantityFormatter value={game?.awayScore} />
        </ScoreSups>
    </div>
);

const GamePreview = ({ game, isReport = false }) => {
    const product = useSelector(productSelector);

    const homeTeamLink = `${
        product === SCOUT
            ? productToPath[PREMIUM].path
            : productToPath[product === PREMIUM_PLUS ? PREMIUM : product].path
    }/team/${game?.homeTeam?.id}`;

    const awayTeamUrl = `${
        product === SCOUT
            ? productToPath[PREMIUM].path
            : productToPath[product === PREMIUM_PLUS ? PREMIUM : product].path
    }/team/${game?.awayTeam?.id}`;

    return (
        <div className={cx({ [styles.reportGameHeader]: isReport, [styles.header]: !isReport })}>
            <div
                className={cx(styles.headerColumn, {
                    [styles.headerColumnLeft]: !isReport,
                    [styles.headerColumnLeftReport]: isReport
                })}
                title={game?.homeTeam?.nameEn || ''}
            >
                <RedirectLink url={homeTeamLink}>
                    <img alt="home" src={game?.homeTeam?.logo} className={cx(styles.headerSubitem, styles.logo)} />
                </RedirectLink>
                <div className={styles.teamName}>
                    <RedirectLink
                        url={homeTeamLink}
                        className={cx({
                            [styles.reportHeaderColumnLeftReportLink]: isReport,
                            [styles.reportHeaderColumnLeftLink]: !isReport
                        })}
                    >
                        <FormattedMessage id="entities.team.name" values={game?.homeTeam} />
                    </RedirectLink>
                </div>
            </div>

            <div
                className={cx(styles.headerColumn, {
                    [styles.headerColumnCenter]: !isReport,
                    [styles.reportHeaderColumnCenter]: isReport
                })}
            >
                <GameScore game={game} isReport={isReport} />
                <PeriodsScore game={game} isReport={isReport} />
            </div>

            <div
                className={cx(styles.headerColumn, {
                    [styles.headerColumnRight]: !isReport,
                    [styles.headerColumnRightReport]: isReport
                })}
            >
                <RedirectLink url={awayTeamUrl}>
                    <img alt="away" src={game?.awayTeam?.logo} className={cx(styles.headerSubitem, styles.logo)} />
                </RedirectLink>
                <div className={styles.teamName} title={game?.awayTeam?.nameEn || ''}>
                    <RedirectLink
                        className={cx({
                            [styles.reportHeaderColumnRightReportLink]: isReport,
                            [styles.reportHeaderColumnRightLink]: !isReport
                        })}
                        url={awayTeamUrl}
                    >
                        <FormattedMessage id="entities.team.name" values={game?.awayTeam} />
                    </RedirectLink>
                </div>
            </div>
        </div>
    );
};

export default GamePreview;
