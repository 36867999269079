import styled from 'styled-components/macro';

export const ReportContainer = styled.div`
    width: 1600px;
    margin: 0 auto;
    padding: 10px;

    background-color: transparent !important;
`;

export const ReportSection = styled.div`
    margin-bottom: 50px;

    table thead th {
        overflow: hidden !important;

        color: rgb(102, 102, 102) !important;
        text-align: left !important;

        background-color: transparent !important;
    }

    table tr td {
        padding-left: 5px;
        overflow: hidden !important;

        text-align: left !important;
    }

    table tbody tr:nth-of-type(odd) {
        overflow: hidden !important;

        background-color: rgb(246, 248, 251) !important;
    }
`;

export const ReportBlock = styled.div`
    margin-top: 150px;
    overflow: hidden !important;
`;

export const ReportBlockFaceOff = styled.div`
    margin-top: 100px;
`;

export const GameSummaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1580px;
    margin-bottom: 10px;
    margin-left: -105%;
`;

export const GameSummaryBlock = styled.div`
    width: 80%;
`;

export const GameSummaryDate = styled.div`
    color: rgb(102, 102, 102);
    font-weight: normal;
    font-size: 18px;
    font-family: Akatab, sans-serif;
    line-height: 20px;
`;

export const GameSummaryShotsBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ isPlayerReport }) => (isPlayerReport ? '100px' : 0)};
    padding: ${({ isPlayerReport }) => (isPlayerReport ? '50px 0' : '200px 0')};
`;

export const BlocksTitle = styled.div`
    display: flex;
    gap: 10px;

    justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
    align-items: center;
    margin: 10px 0;

    color: rgb(72, 82, 115);

    font-weight: 600;
    font-size: 20px;
`;

export const TeamTitle = styled.span`
    color: ${({ color }) => (color ? color : 'rgb(72, 82, 115)')};

    font-weight: 600;
    font-size: 20px;
`;

export const TableTitle = styled.div`
    margin: 10px 0;

    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
`;

export const TableGameTitle = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

export const TableGameHeader = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-around;
    align-items: center;
`;

export const TableCell = styled.div`
    min-height: 12.5px;

    color: ${({ color }) => (color ? color : 'rgb(102, 102, 102)')};
    font-weight: 600;
    font-size: ${({ isHeading }) => (isHeading ? '14px' : '12px')};
    text-align: left;
`;

export const LeaderTableBlock = styled.div`
    width: 100%;
`;

export const TableCellWithPercent = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 5px;

    div {
        min-width: 20px;
    }
`;

export const ReportPpPkBlock = styled.div`
    margin-top: 20px;
    margin-bottom: 100px;
    overflow: hidden !important;
`;

export const ReportLeaderBlock = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 15px;
    overflow: hidden !important;
`;

export const PlayerReportSummaryContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-around;
    align-items: center;

    text-align: center;
`;
export const PlayerReportSummaryBlock = styled.div`
    flex: 1 0 110px;
    height: 143px;
`;
export const PlayerReportSummaryValue = styled.div`
    display: inline-block;
    width: 100%;
    margin-top: 40px;

    color: var(--uiPrimaryColorActive);
    font-weight: 600;
    font-size: 33px;
    font-family: Roboto, sans-serif;
    letter-spacing: -1.5px;
    text-align: center;

    cursor: default;
`;
export const PlayerReportSummaryLabel = styled.div`
    display: inline-block;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;

    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.33;
    text-align: center;
    text-transform: uppercase;
`;
export const PlayerReportSummarySeparator = styled.div`
    flex: 0 0 1px;
    height: 80px;
    margin-right: -1px;

    background-color: #d6dae3;
`;

export const PlayerReportProfileContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const PlayerReportProfileNameContainer = styled.div`
    display: flex;
    gap: 10px;
`;
export const PlayerReportProfileName = styled.div`
    color: #485273;
    font-weight: 400;
    font-size: 34px;
`;
export const PlayerReportProfilePosition = styled.div`
    align-self: end;

    color: #485273;
`;

export const GoalieFaceMap = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px 0;
`;

export const GoalieShotsBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;
