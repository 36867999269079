import {
    fieldShotsSelector,
    goalKeeperShotsSelector,
    shotsSelector
} from '../../../containers/widgets-stats/selectors/shots';
import createStatsSelector, { markNonStat } from '../../../selectors/stats/private/create-stats-selector';
import { allGamePlayersWithProfilesSelector } from '../../../containers/widgets-stats/selectors/game-players';
import { collect } from '../../../selectors/utils/collect';
import * as collectors from '../../../selectors/utils/collectors';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';

const HOME = 'HOME';
const AWAY = 'AWAY';

/**
 * @return dictionary (map) gamePlayerId to field player instance without position filtration
 */

const gamePlayerIdToPlayerInfoSelector = createSelector(
    allGamePlayersWithProfilesSelector,
    players => {
        if (!players || !players.data) {
            return {
                processing: players.processing,
                data: {}
            };
        }

        return {
            processing: players.processing,
            data: keyBy(players.data, 'playerId')
        };
    }
);

/*Sorted by gamePlayerId - v2*/
const gamePlayerIdToPlayerInfoSelectorV2 = createSelector(
    allGamePlayersWithProfilesSelector,
    players => {
        if (!players || !players.data) {
            return {
                processing: players.processing,
                data: {}
            };
        }

        return {
            processing: players.processing,
            data: keyBy(players.data, 'gamePlayerId')
        };
    }
);

/**
 * shots which should be drawn on shot map
 */
export const getShots = (state, props) => {
    const { keeperShots, singleTeam } = props;

    if (keeperShots) {
        return goalKeeperShotsSelector(state, props);
    } else if (singleTeam) {
        return shotsSelector(state, props);
    } else {
        return fieldShotsSelector(state, props);
    }
};

/**
 * max and min xg values for shot
 */

export const shotXgBoundsSelector = createStatsSelector(
    props => props.shots || props.points,
    shots =>
        collect(shots, {
            maxXg: collectors.max(shot => shot.xgValue),
            minXg: collectors.min(shot => shot.xgValue)
        })
);

/**
 * sorted shots (sort shots because of overlapping in svg, goals should be drawing after usual shots)
 */
const sortedShotsSelector = createStatsSelector(getShots, shots =>
    [...(shots ?? [])].sort((s1, s2) => s1.goal - s2.goal)
);

const alwaysTrue = () => true;

export const createShotPointsSelector = (shotsSelector, hasRevertSides) =>
    createStatsSelector(
        shotsSelector,
        gamePlayerIdToPlayerInfoSelectorV2,
        markNonStat((state, props) => props.shotsFilter || props.filter || alwaysTrue),
        (shots, gamePlayerIdToPlayerByPlayerId2, predicate) => {
            const isMultipleGames = Object.keys(groupBy(shots, 'gameId'))?.length > 1;

            if (!hasRevertSides) {
                return shots.filter(predicate).map(shot => {
                    const player = gamePlayerIdToPlayerByPlayerId2?.[shot?.gamePlayerId];

                    const { inUserTeam } = player || {};

                    const goalkeeperId = inUserTeam ? shot?.awayGoalkeeperId : shot?.homeGoalkeeperId;

                    const goalkeeper = gamePlayerIdToPlayerByPlayerId2?.[goalkeeperId];

                    return shotPointData(
                        player,
                        {
                            ...shot,
                            eventTeamStatus: inUserTeam ? HOME : AWAY,
                            x: inUserTeam ? (shot.x < 0 ? shot.x * -1 : shot.x) : shot.x > 0 ? shot.x * -1 : shot.x
                        },
                        inUserTeam,
                        goalkeeper
                    );
                });
            }

            /*Cases for summary page*/

            if (isMultipleGames) {
                return shots.filter(predicate).map(shot => {
                    const player = gamePlayerIdToPlayerByPlayerId2?.[shot?.gamePlayerId];

                    const { inUserTeam } = player || {};

                    const goalkeeperId = inUserTeam ? shot?.awayGoalkeeperId : shot?.homeGoalkeeperId;

                    const goalkeeper = gamePlayerIdToPlayerByPlayerId2?.[goalkeeperId];

                    return shotPointData(
                        player,
                        {
                            ...shot,
                            eventTeamStatus: inUserTeam ? HOME : AWAY,
                            x: inUserTeam ? (shot.x < 0 ? shot.x * -1 : shot.x) : shot.x > 0 ? shot.x * -1 : shot.x
                        },
                        !inUserTeam,
                        goalkeeper
                    );
                });
            }

            return shots.filter(predicate).map(shot => {
                const player = gamePlayerIdToPlayerByPlayerId2?.[shot.gamePlayerId];

                const isShotForHome = shot?.eventTeamStatus === HOME;

                const goalkeeperId = isShotForHome ? shot?.awayGoalkeeperId : shot?.homeGoalkeeperId;

                const goalkeeper = gamePlayerIdToPlayerByPlayerId2?.[goalkeeperId];

                return singleGameShotPointData(
                    player,
                    {
                        ...shot,
                        x: isShotForHome ? (shot.x < 0 ? shot.x * -1 : shot.x) : shot.x > 0 ? shot.x * -1 : shot.x
                    },
                    !isShotForHome,
                    goalkeeper
                );
            });
        }
    );

/**
 * unfiltered shot points (shot with fat data, include goalkeeper profile, player profile)
 */
const unfilteredShotPointsSelector = createStatsSelector(
    sortedShotsSelector,
    gamePlayerIdToPlayerInfoSelector,
    (shots, gamePlayerIdToPlayer) => {
        return shots.map(shot => {
            const player = gamePlayerIdToPlayer[shot.gamePlayerId];

            const isShotForHome = shot.eventTeamStatus === HOME;
            const goalkeeperId = isShotForHome ? shot.awayGoalkeeperId : shot.homeGoalkeeperId;

            const goalkeeper = gamePlayerIdToPlayer[goalkeeperId];

            return shotPointData(player, shot, isShotForHome, goalkeeper);
        });
    }
);

/**
 * filtered shot points
 */
export const shotPointsSelector = createStatsSelector(
    unfilteredShotPointsSelector,
    markNonStat((state, props) => props.filter),
    (shots, predicate) => {
        return predicate ? shots.filter(predicate) : shots;
    }
);

const shotPointData = (player, shot, isShotForHome, goalkeeper) => {
    const { id, homePlayersNumber, awayPlayersNumber } = shot;

    if (!player) {
        return {
            ...shot,
            shotId: id,
            isShotForHome,
            goalkeeper,
            homePlayersNumber,
            awayPlayersNumber,
            id
        };
    }

    const strength =
        player.inUserTeam === isShotForHome
            ? { team: homePlayersNumber, opponent: awayPlayersNumber }
            : { team: awayPlayersNumber, opponent: homePlayersNumber };

    const strengthPresentation = `${strength.team}x${strength.opponent}`;

    return {
        ...shot,
        ...player,
        strength: strengthPresentation,
        shotId: id,
        isShotForHome,
        goalkeeper,
        homePlayersNumber,
        awayPlayersNumber,
        id
    };
};

const singleGameShotPointData = (player, shot, isShotForHome, goalkeeper) => {
    const { id, homePlayersNumber, awayPlayersNumber } = shot;

    if (!player) {
        return {
            ...shot,
            shotId: id,
            isShotForHome,
            goalkeeper,
            homePlayersNumber,
            awayPlayersNumber,
            id
        };
    }

    const strength =
        player.inUserTeam === isShotForHome
            ? { team: homePlayersNumber, opponent: awayPlayersNumber }
            : { team: awayPlayersNumber, opponent: homePlayersNumber };

    const strengthPresentation = `${strength.team}x${strength.opponent}`;

    return {
        ...{ ...player, inUserTeam: !isShotForHome },
        ...{ ...shot, eventTemStatus: isShotForHome ? HOME : AWAY, temStatus: isShotForHome ? HOME : AWAY },
        strength: strengthPresentation,
        shotId: id,
        isShotForHome,
        goalkeeper,
        homePlayersNumber,
        awayPlayersNumber,
        id
    };
};
